<template>
  <div class="register-bind">
    <div class="bg">
      <img src="@/assets/icon/register.png" alt="">
    </div>
    <div class="content">
      <div class="tabs">
        <div v-for="(item,i) in titleList" :key="i" :class="{ active: i === activeColor }" class="user-title" @click="changeHandle(item.type,i)">{{ item.name }}</div>
      </div>
      <div v-if="userType === 'newUser'" class="form">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
          <el-form-item prop="account" :show-message="showAccountTip">
            <el-input
              ref="account"
              v-model="loginForm.account"
              placeholder="登录账号"
              name="account"
              type="text"
              tabindex="1"
              autocomplete="new-text"
              @blur="accountBlur"
            />
          </el-form-item>
          <el-form-item prop="password" :show-message="showPasswordTip">
            <el-input
              ref="password"
              :key="passwordType"
              v-model="loginForm.password"
              :type="passwordType"
              name="password"
              tabindex="2"
              placeholder="设置密码"
              autocomplete="new-password"
              @blur="passwordBlur"
            />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>
          <el-form-item prop="phone" :show-message="showPhoneTip">
            <el-input
              v-model="loginForm.phone"
              placeholder="手机号"
              @blur="phoneBlur"
            >
              <template #prepend>
                +86
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code" :show-message="showCodeTip" class="image-box">
            <el-input
              v-model="loginForm.code"
              placeholder="验证码"
              @blur="codeBlur"
            >
            <!-- <template #append @click="getCode">
              获取验证码
            </template> -->
            </el-input>
            <img class="image" :src="image" alt="" @click="refresh()">
          </el-form-item>
          <el-button :loading="smsLoading" type="primary" style="width:100%;margin-top:10px;" @click.prevent="handleGetSms">获取短信验证码</el-button>
        </el-form>
      </div>
      <div v-else class="form">
        <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
          <el-form-item prop="phone" :show-message="showPhoneTip">
            <el-input
              v-model="loginForm.phone"
              placeholder="手机号"
              @blur="phoneBlur"
            >
              <template #prepend>
                +86
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code" :show-message="showCodeTip" class="code-box">
            <el-input
              v-model="loginForm.code"
              placeholder="验证码"
              @blur="codeBlur"
            >
              <template #append>
                <span v-show="showMsg" @click="getCode">获取验证码</span>
                <span v-show="!showMsg">{{ count }} s后重新获取</span>
              </template>
            </el-input>
          </el-form-item>
          <el-button :loading="loading" type="primary" style="width:100%;" @click.prevent="handleBind">立即绑定</el-button>
        </el-form>
      </div>
    </div>
    <!--    手机验证弹窗-->
    <div class="mobile-test creat-dialog">
      <el-dialog
        v-model="mobileTestVisible"
        title="验证手机"
        :close-on-click-modal="false"
        width="40%"
        :before-close="verifyMobile"
      >
        <div class="dialog-content">
          <div class="test-tip">
            <!-- <svg-icon icon-class="warning" class="svg" /> -->
            验证码已发送到您的手机，10分钟内输入有效，请勿泄露。
          </div>
          <el-form
            ref="applyBillForm"
            :model="mobileForm"
            :rules="mobileFormRules"
            label-width="180px"
            label-position="right"
          >
            <el-form-item label="手机号码" style="margin-bottom: 20px;">
              {{ loginForm.phone }}
            </el-form-item>
            <el-form-item ref="mobileSmsCode" class="form-inline" label="验证码" :show-message="showCodeInputTip" prop="mobileCode" style="margin-bottom: 20px;">
              <div style="display:flex;justify-content:space-around;">
                <el-input v-model="mobileForm.mobileCode" placeholder="请输入验证码" @blur="codeInputBlur" />
                <el-button class="send" :disabled="num !== ''" :loading="sendLoading" @click="send">
                  重发{{ num ? '(' + num + ')' : '' }}
                </el-button>
              </div>
              <div style="color: #2a9c3d;font-size: 12px;line-height: 1;margin-top: 6px;">
                请输入您的手机收到的验证码
              </div>
            </el-form-item>
          </el-form>
          <div style="text-align: center;">
            <el-button style="width: 120px;" type="primary" :loading="submitLoading" @click="submit">立即注册</el-button>
          </div>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import { bindByPhone, register, captcha, getUserIsExist, getBindPhoneSms, getBindRegisterSms } from '@/api/user'
import { ElMessage } from 'element-plus'
import { setToken } from '@/utils/auth'
export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      const reg = /^\d*([a-zA-Z-_])+\d*$/
      if (value.trim() === '') {
        callback(new Error('请输入用户名'))
      } else if (value.trim().length < 2 || value.trim().length > 50) {
        callback(new Error('用户名长度应在2-50个字符之间'))
      } else if (!reg.test(value.trim())) {
        callback(new Error('请输入英文数字或者_-且不能仅有数字'))
      } else {
        getUserIsExist(value.trim()).then((response) => {
          if (response.meta.status === 467) {
            callback()
          }
          if (response.meta.status === 200) {
            callback(new Error('用户名已注册'))
          }
        }).catch((err) => {
          callback(new Error(err.message))
        })
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6 && value.length > 0) {
        callback(new Error('密码长度不能小于6'))
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
          // mobileCheck(value, this.uId).then((response) => {
          //   callback()
          // }).catch((err) => {
          //   callback(new Error(err.message))
          // })
        }
      }
    }
    return {
      // 短信验证码弹框
      showMsg: true,
      count: '',
      timer: null,
      smsLoading: false,
      mobileTestVisible: false,
      num: '',
      submitLoading: false,
      sendLoading: false,
      mobileForm: {
        mobileCode: ''
      },
      mobileFormRules: {
        mobileCode: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      },

      passwordType: 'password',
      activeColor: 0,
      userType: 'newUser',
      titleList: [{
        name: '我是新用户',
        type: 'newUser'
      }, {
        name: '我是老用户',
        type: 'oldUser'
      }],
      showCodeTip: true,
      showCodeInputTip: true,
      showPhoneTip: true,
      showPasswordTip: true,
      showAccountTip: true,
      autoLogin: false,
      image: '',
      keys: '',
      loginForm: {
        account: '',
        password: '',
        phone: '',
        code: ''
      },
      loginRules: {
        phone: [{ required: true, trigger: 'blur', validator: validatePhone }],
        account: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        code: [
          { required: true, message: '请根据验证码填写验证码', trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    codeInputBlur() {
      this.showCodeInputTip = Boolean(this.mobileForm.mobileCode)
    },
    // 获取验证码
    getCode() {
      if (this.loginForm.phone === '') {
        ElMessage.warning('请填写手机号!')
        return
      }
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.showMsg = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.showMsg = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
      getBindPhoneSms(this.loginForm.phone).then((response) => {
        console.log(JSON.stringify(response))
      })
    },
    refresh() {
      captcha().then((response) => {
        this.image = response.data.image
        this.keys = response.data.key
      })
    },
    changeHandle(type, i) {
      this.userType = type
      this.activeColor = i
      this.loginForm.phone = ''
      this.loginForm.code = ''
    },
    phoneBlur() {
      this.showPhoneTip = Boolean(this.loginForm.phone)
    },
    codeBlur() {
      this.showCodeTip = Boolean(this.loginForm.code)
    },
    passwordBlur() {
      this.showPasswordTip = Boolean(this.loginForm.password)
    },
    accountBlur() {
      this.showAccountTip = Boolean(this.loginForm.account)
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 立即绑定
    handleBind() {
      this.loading = true
      const unionId = localStorage.unionId
      const code = this.loginForm.code
      const phone = this.loginForm.phone
      this.$refs['loginFormRef'].validate(valid => {
        if (valid) {
          bindByPhone(code, phone, unionId).then((response) => {
            if (response.meta.status === 201) {
              ElMessage.success('绑定成功')
              this.$router.push('/')
              this.loading = false
            } else {
              ElMessage.error(response.meta.msg)
              this.loading = false
            }
          }).catch(() => {
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
    // 注册相关
    verifyMobile(done) {
      done()
      this.refresh()
    },
    // 计时器
    timing() {
      this.num = 60
      const time = setInterval(() => {
        this.num--
        if (this.num === 0 || this.mobileTestVisible === false) {
          this.num = ''
          clearInterval(time)
        }
      }, 1000)
    },
    send() {
      this.sendLoading = true
      const data = {
        account: this.loginForm.account.trim(),
        captchaKey: this.keys,
        code: this.loginForm.code,
        phone: this.loginForm.phone
      }
      getBindRegisterSms(data).then(res => {
        if (res.meta.status === 200) {
          this.timing()
          setTimeout(() => {
            this.sendLoading = false
          }, 350)
        } else {
          this.sendLoading = false
          ElMessage.error(res.meta.msg)
        }
      }).catch(error => {
        console.log(error)
        setTimeout(() => {
          this.sendLoading = false
        }, 350)
      })
    },
    submit() {
      this.submitLoading = true
      this.showCodeInputTip = true
      const data = {
        account: this.loginForm.account.trim(),
        password: this.loginForm.password,
        unionId: localStorage.unionId,
        code: this.mobileForm.mobileCode,
        mobile: this.loginForm.phone,
        projectId: 5726455318996758
      }
      this.$refs['applyBillForm'].validate(valid => {
        if (valid) {
          register(data).then((response) => {
            if (response.meta.status === 201) {
              this.$store.commit('user/SET_TOKEN', response.data)
              setToken(response.data)
              this.$nextTick(() => {
                this.$router.push('/desktop')
              })
              // this.mobileTestVisible = false
              // this.submitLoading = false
              // this.$refs.loginForm.resetFields()
              // ElMessage.success('注册成功！')
            } else {
              ElMessage.error(response.meta.msg)
              this.mobileTestVisible = false
              this.submitLoading = false
              this.refresh()
              this.$refs.loginForm.resetFields()
            }
          }).catch(() => {
            this.mobileTestVisible = false
            this.submitLoading = false
            this.refresh()
            this.$refs.loginForm.resetFields()
          })
        } else {
          this.submitLoading = false
        }
      })
    },
    // 获取验证码
    handleGetSms() {
      this.smsLoading = true
      this.showPhoneTip = true
      this.showCodeTip = true
      this.showPasswordTip = true
      this.showAccountTip = true
      const data = {
        account: this.loginForm.account.trim(),
        captchaKey: this.keys,
        code: this.loginForm.code,
        phone: this.loginForm.phone
      }
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          getBindRegisterSms(data).then((res) => {
            if (res.meta.status === 200) {
              this.timing()
              this.mobileTestVisible = true
              this.smsLoading = false
            } else {
              this.smsLoading = false
              ElMessage.error(res.meta.msg)
            }
          }).catch(() => {
            this.smsLoading = false
          })
        } else {
          this.smsLoading = false
        }
      })
    },
    // 立即注册
    handleRegister() {
      this.showPhoneTip = true
      this.showCodeTip = true
      this.showPasswordTip = true
      this.showAccountTip = true
      // const data = {
      //   account: this.loginForm.account,
      //   password: this.loginForm.password,
      //   codeKey: this.keys,
      //   code: this.loginForm.code,
      //   mobile: this.loginForm.phone,
      //   projectId: 5726455318996758
      // }
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          // register().then((response) => {
          //   this.$router.push('/')
          //   this.loading = false
          // }).catch(() =>{
          //   this.loading = false
          //   this.refresh()
          // })
          this.loading = false
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.register-bind{
  width: 100vw;
  height: 100vh;
  .mobile-test {
      font-size: 12px;
      .form-inline{
        display: flex;
        ::v-deep .el-input{
          width: 135px;
        }
        ::v-deep .el-input__inner{
          width: 135px;
        }
      }
      .test-tip {
        margin: 20px;
        height: 40px;
        line-height: 40px;
        background: #f9f1da;
        padding-left: 15px;
        color: #202020;
        .svg {
          margin: 0 10px;
        }
      }
    }

  .bg {
    img {
      width: 100vw;
      height: 100vh;
    }
  }
  ::v-deep .el-input__inner{
      height: 32px;
    }
  .el-button--primary{
    height: 32px;
    background: #2385FF;
    border: 1px solid #2385FF;
    box-shadow: 0px 2px 10px 0px rgba(20, 75, 144, 0.5), 0px 1px 3px 0px #D2D6EA;
    border-radius: 2px;
  }
  .el-button{
    font-size: 12px;
    margin-left: 10px;
  }
  .content{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 600px;
    border-radius: 8px;
    background: #F5F5F5;
    .form{
      padding: 22px 100px 60px 100px;
    .code-box{
    ::v-deep .el-input-group__append, .el-input-group__prepend{
      background-color: #fff;
      color: #2385FF;
    }
  }
  .image-box{
    ::v-deep .el-input__inner{
      width: 180px;
    }
    .el-input{
      width: 180px;
    }
    img{
      width: 150px;
      height: 50px;
      margin-left: 46px;
    }
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 16px;
    color: #313A46;
    cursor: pointer;
    user-select: none;
  }
    }
    .tabs{
      display: flex;
      .user-title{
        // height: 48px;
        // line-height: 48px;
        padding: 16px 0;
        text-align: center;
        width: 50%;
        font-weight: 500;
        color: #313A46;
        font-size: 16px;
        background: #E1E1E1;
      }
      .user-title:first-child{
        border-top-left-radius: 8px;
      }
      .user-title:last-child{
        border-top-right-radius: 8px;
      }
      .active{
        background: #fff;
        color: #2385FF;
        border-bottom: 1px solid #2385FF;
      }
    }
  }
}
</style>
